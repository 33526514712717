import React, { useState, useEffect } from "react";
import { ListGroup, Card, Button, Pagination, Spinner } from "react-bootstrap";
import axios from "axios";

function LogComponent() {
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [logSource, setLogSource] = useState("dev");

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setLoading(true);
        const base_url = process.env.REACT_APP_API_KEY;
        console.log("base_url", base_url);

        let apiUrl = `${base_url}/api/log`;
        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
          apiUrl += logSource === "prod" ? "&prod_logs=true" : "";
        }
        // const response = await fetch(apiUrl, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });

        const response = await axios.get(apiUrl);
        debugger;
        const data = response.data;
        let reslogs = data["data"];
        setLogs(reslogs);

      } catch (error) {
        console.error("Error fetching logs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [currentPage, logSource]);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const getUserResponse = (response) => {
    if (response === null) {
      return "No response";
    } else if (response === 0) {
      return "Disliked";
    } else {
      return "Liked";
    }
  };

  const handleLogSourceToggle = () => {
    setLogSource(logSource === "dev" ? "prod" : "dev");
    setCurrentPage(1);
  };

  function displayIntent(intent) {
    if (intent) {
      try {
        if (typeof intent === "object") {
          // If intent is an object, convert it to a JSON string
          return <pre>{JSON.stringify(intent, null, 1)}</pre>;
        } else {
          // If intent is not an object, convert it to a string
          return String(intent);
        }
      } catch (error) {
        console.log("error intent", intent);
        return "None";
      }
    }
    return "None";
  }

  return (
    <div>
      {process.env.REACT_APP_ENVIRONMENT === "dev" && (
        <Button
          variant="secondary"
          className="mb-2"
          onClick={handleLogSourceToggle}
        >
          {logSource.toUpperCase()}
          {console.log("logSource", logs)}
        </Button>
      )}

      <Pagination className="mt-3">
        <Pagination.Prev
          onClick={handlePrevClick}
          disabled={currentPage === 1}
        />
        <Pagination.Next onClick={handleNextClick} />
      </Pagination>

      {loading && (
        <Spinner animation="border" role="status" className="my-3"></Spinner>
      )}
      {!loading &&
        logs.map((log, index) => (
          <Card key={index} className="my-3">
            <Card.Body>
              <Card.Title>{log?.query.split("Logs:")[0]}</Card.Title>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Status Code:</strong> {log?.status_code}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Resolution:</strong> {log?.resolution}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Error Message:</strong> {log.error_message}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>User Response:</strong>
                  {getUserResponse(log.user_response)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Created At:</strong>
                  {new Date(log.created_at).toLocaleString()}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
}

export default LogComponent;
