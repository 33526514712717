import { DatePicker } from "antd";
import React from "react";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const CustomDatePicker = ({
  required,
  error,
  id,
  name,
  placeholder,
  onChange,
  value,
  onBlur,
  onOk,
}) => {
  const disabledDate = (currentDate, info) => {
    // Disable dates after today (future dates)
    return currentDate.isAfter(dayjs(), "day");
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col">
        <div className="py-2 rounded-xl flex gap-1 flex-col items-center">
          <RangePicker
            showTime={{
              format: "HH:mm:ss",
            }}
            format="YYYY-MM-DD HH:mm:ss"
            className="w-full"
            id={id}
            name={name}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            onOk={onOk}
            disabledDate={disabledDate}
          />
          {error && <div className="text-red-500 text-sm">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;
