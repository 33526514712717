import React, { useState } from "react";
import ChatComponent from "../../Components/ChatComponent";
import { Button, ButtonGroup } from "react-bootstrap";
import LogComponent from "../../Components/LogComponent";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Heading, useTheme } from "@aws-amplify/ui-react";

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your username",
    },
  },
};

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      return (
        <div className="flex items-center justify-center text-center text-[15px] font-semibold mb-8">
          <p className="m-0">
            Interested in exploring our demo but don't have access?{" "}<br /> Simply{" "}
            <a
              href="https://mlexperts.ai/contactus"
              className="text-[#4183c4] no-underline "
            >
              contact our team
            </a>{" "}
            for assistance, and we'll help you get {" "}<br />started.
          </p>
        </div>
      );
    },
  },
};

const Chat = () => {
  const [activeItem, setActiveItem] = useState("chat");

  const handleItemClick = (name) => setActiveItem(name);
  return (
    <Authenticator
      variation="modal"
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      <div className="lg:px-20 md:px-8 sm:px-5 px-5 py-5 ">
        <div className="bg-[#F8F9FA] p-4 rounded-lg">
          <ButtonGroup className="mb-3">
            <Button
              variant={activeItem === "chat" ? "primary" : "secondary"}
              onClick={() => handleItemClick("chat")}
            >
              Chat
            </Button>

            <Button
              variant={activeItem === "logs" ? "primary" : "secondary"}
              onClick={() => handleItemClick("logs")}
            >
              Logs
            </Button>
          </ButtonGroup>
          {activeItem === "chat" && <ChatComponent />}
          {activeItem === "logs" && <LogComponent />}
        </div>
      </div>
    </Authenticator>
  );
};

export default Chat;
