export const boxData = [
  {
    title:
      "I tried retrieving my mutual fund transaction history but got an error message saying 'Data Not Found'. Can you assist me in resolving this issue?",
    logs: `2024-01-30 16:00:01 - DEBUG - Querying database for user's mutual fund transactions.,
    2024-01-32024-01-30 16:00:01 - INFO - User requested mutual fund transaction history retrieval.,     
    0 16:00:02 - WARNING - No mutual fund transaction data found for the user.,     
    2024-01-30 16:00:02 - ERROR - Data Not Found error in fetching mutual fund transactions.,     
    2024-01-30 16:00:03 - INFO - Informed user about 'Data Not Found' and advised to check account details.`,
    start_times: ["2024-02-11 05:00:00", "2024-02-11 07:04:00"],
  },
  {
    title:
      "I attempted to delete a mutual fund from my portfolio, but it failed with the message 'Deletion Error - Transaction not found'. What should I do?",
    logs: `2024-01-30 18:20:01 - INFO - User requested to delete a mutual fund from their portfolio.,
    2024-01-30 18:20:01 - DEBUG - Searching for the specified mutual fund transaction in the portfolio.,
    2024-01-30 18:20:02 - WARNING - Specified mutual fund transaction not found in the user's portfolio.,
    2024-01-30 18:20:02 - ERROR - Deletion Error: Transaction not found.,
    2024-01-30 18:20:03 - INFO - Informed user that the transaction could not be found and suggested verifying transaction details.
    `,
    start_times: ["2024-02-11 07:00:00", "2024-02-11 09:04:00"],
  },
  {
    title:
      "I'm trying to find out the dividend types for a mutual fund, but keep getting 'Retrieval Error - Dividend information unavailable'. What's the problem?",
    logs: `2024-01-30 20:40:01 - INFO - User requested dividend information for a specific mutual fund.,
    2024-01-30 20:40:02 - DEBUG - Searching for dividend information in the mutual fund database.,
    2024-01-30 20:40:03 - WARNING - Dividend information for the requested mutual fund is not available.,
    2024-01-30 20:40:03 - ERROR - Retrieval Error: Dividend information unavailable.`,
    start_times: ["2024-02-11 09:00:00", "2024-02-11 11:04:00"],
  },
  {
    title:
      "Whenever I try to set up a SIP purchase, I encounter 'SIP Setup Error - Invalid SIP details'. How can I resolve this?",
    logs: `2024-01-30 23:40:01 - INFO - User attempted to set up a SIP purchase.,
    2024-01-30 23:40:02 - DEBUG - Validating SIP details for setup process.,
    2024-01-30 23:40:03 - WARNING - SIP details provided by the user are invalid or incomplete.,
    2024-01-30 23:40:03 - ERROR - SIP Setup Error: Invalid SIP details.,
    2024-01-30 23:40:04 - INFO - Advised user to review and correct their SIP details for setup.`,
    start_times: ["2024-02-11 13:00:00", "2024-02-11 15:04:00"],
  },
  {
    title:
      "I can't complete a one-time lumpsum purchase of a mutual fund. It's giving me an error ",
    logs: `2024-01-30 22:30:01 - INFO - User initiated one-time lumpsum purchase of a mutual fund.,
    2024-01-30 22:30:02 - DEBUG - Processing payment for the lumpsum purchase.,
    2024-01-30 22:30:03 - WARNING - Payment authorization failed for the transaction.,
    2024-01-30 22:30:03 - ERROR - Purchase Failure: Payment declined.,
    2024-01-30 22:30:04 - INFO - Advised user to check their payment details and bank status.`,
    start_times: ["2024-02-11 17:00:00", "2024-02-11 19:04:00"],
  },
];
