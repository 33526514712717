import React, { useState } from "react";
import CustomTextarea from "../../Custom/CustomTextarea";
import CustomDatePicker from "../../Custom/CustomDatePicker";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { boxData } from "./Assets";
import { Button, ButtonGroup, Col, Row, Spinner } from "react-bootstrap";
import Markdown from "react-markdown";
import dayjs from "dayjs";

export const queryValidationSchema = Yup.object().shape({
  query: Yup.string().required("Query is required"),
  start_time: Yup.mixed().required("Incident time is required"),
});

const ChatComponent = () => {
  const [resolution, setResolution] = useState("");
  const [document, setDocument] = useState({});
  const [ticket, setTickets] = useState("");
  const [logs, setLogs] = useState("");
  const [intent, setIntent] = useState("");
  const [activeItem, setActiveItem] = useState("resolution");
  const [loading, setLoading] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [logId, setLogId] = useState("");

  const handleItemClick = (name) => setActiveItem(name);
  const baseUrl = process.env.REACT_APP_API_KEY;
  const initialValues = {
    query: "",
    start_time: "",
  };

  const handleClick = (desc, start_times, formik) => {
    formik.setFieldValue("query", desc);
    formik.setFieldValue("start_time", start_times);
  };

  const formik = useFormik({
    validationSchema: queryValidationSchema,
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${baseUrl}/api/query?error_message=${encodeURIComponent(
            values.query
          )}`,
          {
            start_time: values.start_time[0],
            end_time: values.start_time[1],
          }
        );
        formik.resetForm();
        setLoading(false);
        setShowTabs(true);
        setResolution(response?.data?.response);
        setDocument(response?.data?.documents);
        setIntent(response?.data?.intent);
        setLogs(response?.data?.logs);
        setTickets(response?.data?.tickets);
        setLogId(response?.data?.log_id);
        message.success("Query solution fetched successfully !");
      } catch (error) {
        console.log(error);
        setLoading(false);
        message.error("Error in fetching solution!");
      }
    },
  });

  const handleLike = () => {
    try {
      const response = axios.post(
        `${baseUrl}/api/log?log_id=${logId}&user_response=like`
      );
      console.log(response);
      message.success("Liked");
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const handleDislike = () => {
    try {
      const response = axios.post(
        `${baseUrl}/api/log?log_id=${logId}&user_response=dislike`
      );
      console.log(response);
      message.success("Disliked");
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-3">
        <form onSubmit={formik.handleSubmit}>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h2>Troubleshooting Timeframe</h2>
              <CustomDatePicker
                id="start_time"
                name="start_time"
                placeholder="Select Date and time"
                required={true}
                value={
                  formik.values.start_time
                    ? [
                        dayjs(formik.values.start_time[0]),
                        dayjs(formik.values.start_time[1]),
                      ]
                    : undefined
                }
                onChange={
                  (dates, dateStrings) =>
                    formik.setFieldValue("start_time", dateStrings) // Set the value of the range
                }
                disabledDate
                onBlur={formik.handleBlur}
                onOk={formik.handleOk}
                error={formik.touched.start_time && formik.errors.start_time}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h2>Issues</h2>
              <CustomTextarea
                placeholder="Enter your issues along with the logs here"
                height="180px"
                id="query"
                name="query"
                required={true}
                value={formik.values.query}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.query && formik.errors.query}
              />
              <br />
              <Button type="submit">
                {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
              </Button>
            </Col>
          </Row>
        </form>
        <div className="flex flex-col gap-3">
          <h2>Example Issues</h2>
          {boxData.map((item, index) => (
            <div
              className="border cursor-pointer hover:bg-[#6c757d] hover:text-white  rounded text-[black] border-black py-2 px-2 flex items-center"
              onClick={() =>
                handleClick(
                  item?.title + "\nLogs:\n" + item?.logs,
                  item?.start_times,
                  formik
                )
              }
              key={index}
            >
              <div className=" font-medium hover:text-white flex-grow pl-3 text-left">
                {item?.title}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showTabs && (
        <>
          <ButtonGroup className="mb-3 mt-5">
            <Button
              variant={activeItem === "resolution" ? "primary" : "secondary"}
              onClick={() => handleItemClick("resolution")}
            >
              Resolution
            </Button>

            <Button
              variant={activeItem === "document" ? "primary" : "secondary"}
              onClick={() => handleItemClick("document")}
            >
              Relevant Documents
            </Button>
            <Button
              variant={activeItem === "tickets" ? "primary" : "secondary"}
              onClick={() => handleItemClick("tickets")}
            >
              Relevant Tickets
            </Button>
            <Button
              variant={activeItem === "logs" ? "primary" : "secondary"}
              onClick={() => handleItemClick("logs")}
            >
              Logs
            </Button>
            <Button
              variant={activeItem === "intent" ? "primary" : "secondary"}
              onClick={() => handleItemClick("intent")}
            >
              Intent
            </Button>
          </ButtonGroup>
          {activeItem === "resolution" && <Markdown>{resolution}</Markdown>}
          {activeItem === "document" && (
            <div>
              <div>
                <h4>Context 1:</h4>
                <Markdown>{document?.Context1}</Markdown>
              </div>
              <div className="mt-5">
                <h4>Context 2:</h4>
                <Markdown>{document?.Context2}</Markdown>
              </div>
            </div>
          )}
          {activeItem === "tickets" && (
            <div>
              {ticket.map((item, index) => (
                <div key={index}>
                  <div>
                    <pre>{JSON.stringify(item, null, 2)}</pre>
                  </div>
                </div>
              ))}
            </div>
          )}
          {activeItem === "logs" && (
            <div>
              {logs.map((item, index) => (
                <div key={index} className="mt-3">
                  {item}
                </div>
              ))}
            </div>
          )}
          {activeItem === "intent" && (
            <div>
              <div className="mt-5">
                <pre>{JSON.stringify(intent, null, 2)}</pre>
              </div>
            </div>
          )}
          <div style={{ marginTop: "10px" }}>
            <Button variant="success" onClick={handleLike}>
              Like
            </Button>
            <Button variant="danger" onClick={handleDislike}>
              Dislike
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ChatComponent;
