import React from "react";

const CustomTextarea = ({
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  height,
  required,
  id,
  name,
}) => {
  const textareaStyle = {
    height: height || "120px",
  };
  return (
    <div className="flex flex-col gap-1">
      {label && (
        <label className="block text-base font-medium text-[#666]">
          {label}
          {label ? required && <span className="text-[#407BFF]">*</span> : ""}
        </label>
      )}
      <textarea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        name={name}
        required={required}
        placeholder={placeholder}
        className="w-full text-base font-sans p-2 rounded-lg border border-[#DADADA] background-white focus:outline-none"
        style={textareaStyle}
      />
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
};

export default CustomTextarea;
